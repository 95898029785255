import { Col } from 'antd';
import React from 'react';

interface Props {
  message: string;
}

const MyCasesUserMessage: React.FC<Props> = (props: Props) => {
  const { message } = props;
  return (
    <Col
      span={22}
      style={{
        lineHeight: '1.3em',
        backgroundColor: '#ebf4ff',
        borderRadius: 10,
        padding: 10,
        marginBottom: 10,
      }}
    >
      <span>{message}</span>
    </Col>
  );
};
export default MyCasesUserMessage;
