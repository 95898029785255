import { Button } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { MyCasesContext } from '../..';
import './styles.scss';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

interface Props {}

const MyCasesHeader: React.FC<Props> = (props: Props) => {
  const { state } = useContext(MyCasesContext);

  return (
    <div className="myCasesRightColumnHeader">
      <Row align="middle" style={{ height: 58, padding: '5px 10px' }} justify="space-between">
        <Col span={20}>
          <Row>
            {state.selectedCase && (
              <Col
                style={{
                  background: '#efefef',
                  height: 40,
                  fontWeight: 600,
                  borderRadius: 5,
                  fontSize: '1.2em',
                  padding: '9px 10px',
                }}
              >
                <span style={{ margin: 0 }}>Case #{state.selectedCase.recordNumber}</span>
              </Col>
            )}

            {/* Category */}
            <Col style={{ padding: '0 20px', borderRight: '1px solid #D9DADA' }}>
              <span style={{ opacity: 0.5 }}>Category</span>
              <br />
              {state.selectedCase && (
                <span>{getProperty(state.selectedCase as any, 'Category')}</span>
              )}
            </Col>

            {/* Subcategory */}
            <Col style={{ padding: '0 20px', borderRight: '1px solid #D9DADA' }}>
              <span style={{ opacity: 0.5 }}>Subcategory</span>
              <br />
              {state.selectedCase && (
                <span>{getProperty(state.selectedCase as any, 'SubCategory')}</span>
              )}
            </Col>

            {/* Status */}
            <Col style={{ padding: '0 20px', borderRight: '1px solid #D9DADA' }}>
              <span style={{ opacity: 0.5 }}>Status</span>
              <br />
              {state.selectedCase && (
                <span>{getProperty(state.selectedCase as any, 'Status')}</span>
              )}
            </Col>
          </Row>
        </Col>

        {/* Buttons */}
        <Col span={4} style={{ textAlign: 'right' }}>
          <Button icon="eye-open" text="3" minimal intent="primary" />
          <Button icon="edit" minimal intent="primary" />
        </Col>
      </Row>
    </div>
  );
};
export default MyCasesHeader;
