import { Col } from 'antd';
import React from 'react';
import './styles.scss';

interface Props {
  message: string;
}

const MyCasesClientMessage: React.FC<Props> = (props: Props) => {
  const { message } = props;
  return (
    <Col span={22} className="myCasesClientMessage">
      <span>{message}</span>
    </Col>
  );
};
export default MyCasesClientMessage;
