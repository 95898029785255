import { Button, ButtonGroup, Section, Tab, Tabs } from '@blueprintjs/core';
import { Col, Row } from 'antd';

import React, { useReducer, useState } from 'react';
import { connect } from 'react-redux';

import BlueprintBadge from '../../../../shared/components/BlueprintBadge';
import MyCasesContactDetails from './components/MyCasesContactDetails';
import MyCasesConversationFeed from './components/MyCasesConversationFeed';
import MyCasesHeader from './components/MyCasesHeader';
import MyCasesList from './components/MyCasesList';
import { MY_CASES_SET_ACTIVE_TAB } from './store/constants';
import { IMyCasesReducer, myCasesReducer, MyCasesReducerInitialState, TCase } from './store/reducer';
import './styles.scss';

interface Props {
  userReducer: any;
}

export type TMyCasesContext = {
  state: IMyCasesReducer;
  dispatch: React.Dispatch<any>;
};

export const MyCasesContext = React.createContext<TMyCasesContext>({
  state: MyCasesReducerInitialState,
  dispatch: () => {},
});

const MyCasesViewV2: React.FC<Props> = (props: Props) => {
  const { userReducer } = props;
  const [state, dispatch] = useReducer(myCasesReducer, MyCasesReducerInitialState);

  // MyCasesList variables
  const [messages, setMessages] = useState<any[]>([]);
  const [newMessageCounters, setNewMessageCounters] = useState([]);
  const [myCases, setMyCases] = useState<TCase[]>([]);

  return (
    <MyCasesContext.Provider value={{ state, dispatch }}>
      <Row className="myCasesContainer">
        {/* Left Column */}
        <Col span={5} style={{ height: 'inherit', marginTop: 1 }}>
          <Row align="top">
            {/* Title and Search */}
            <Col span={24} style={{ background: 'white' }}>
              <Section
                title="My Cases"
                className="titleSection"
                rightElement={
                  // By the time we worked on this  prototype, design team still had not provided the filter UI.
                  <Button icon="filter" minimal />
                }
              >
                <div style={{ padding: '10px 20px' }}>
                  <Row justify="space-between">
                    <Col span={24}>
                      {/* My Cases Tab */}
                      <Tabs
                        id="myCasesTabs"
                        selectedTabId={state.selectedCasesTabId}
                        onChange={(id: string) => {
                          dispatch({ type: MY_CASES_SET_ACTIVE_TAB, payload: id });
                        }}
                      >
                        <Tab
                          id="my-queue"
                          title={
                            <span style={{ display: 'inline-block' }}>
                              My Queue
                              <BlueprintBadge number={myCases.length} bold />
                            </span>
                          }
                        />
                        <Tab
                          id="following"
                          title={
                            <span style={{ display: 'inline-block' }}>
                              Following
                              <BlueprintBadge number={myCases.length - (myCases.length - 1)} color="#545a5d" />
                            </span>
                          }
                        />
                      </Tabs>
                    </Col>
                  </Row>
                </div>
              </Section>
            </Col>
          </Row>

          {/* My Cases List */}
          <MyCasesList
            userReducer={userReducer}
            setMessages={setMessages}
            setNewMessageCounters={setNewMessageCounters}
            myCases={myCases}
            setMyCases={setMyCases}
          />
        </Col>

        {/* Right Column */}
        <Col span={19}>
          {/* Case Header */}
          <MyCasesHeader />
          <div style={{ height: 'calc(100vh - 50px)', background: '#fff' }}>
            <Row>
              {/* Conversation Feed */}
              <MyCasesConversationFeed />

              {/* Contact Details */}
              <MyCasesContactDetails />
            </Row>

            <Row>
              <Col
                span={24}
                style={{ height: 57, border: '1px solid #d9dada', background: 'white' }}
              >
                <Row align="middle" style={{ height: '100%', padding: '0 15px' }}>
                  <Col span={12}>
                    {/* Empty left column, probably will be used as the design progresses. */}
                  </Col>

                  <Col span={12} style={{ textAlign: 'right' }}>
                    <ButtonGroup>
                      <Button intent="primary" outlined>
                        Submit as <span style={{ fontWeight: 600 }}>In Progress</span>
                      </Button>
                      <Button icon="caret-down" intent="primary" outlined />
                    </ButtonGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </MyCasesContext.Provider>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCasesViewV2);
