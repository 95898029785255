import {
  Button,
  Card,
  CardList,
  InputGroup,
  NonIdealState,
  Section,
  SectionCard,
  Tag,
} from '@blueprintjs/core';
import { Col, Row } from 'antd';
import { FC, useState } from 'react';
import { CSVLink } from 'react-csv';
import './styles.scss';

type TPanelDataItem = {
  id: string;
  name: string;
  description: string;
  status?: string;
};

// const PanelHeightCSS = '480px';
const PanelHeightCSS = '58vh';

const RIGHT_PANEL_DATA_ITEM_LIMIT = 200;
const LEFT_PANEL_DATA_ITEM_LIMIT = 200;

interface Props {
  leftPanelData: TPanelDataItem[] | undefined;
  leftPanelIcon?: any;
  leftPanelExternalSearch?: string;
  setLeftPanelExternalSearch?: (value: string) => void;
  leftPanelSubtitle?: string;
  leftPanelTitle: JSX.Element | string;
  leftPanelDisableAdding?: boolean;
  onLeftPanelChange: (id: string) => void;
  onRightPanelChange: (id: string) => void;
  rightPanelData: TPanelDataItem[];
  rightPanelIcon?: any;
  rightPanelSubtitle?: string;
  rightPanelTitle: JSX.Element | string;
  rightPanelLink?: string;
}

export const DualPanelAssignment: FC<Props> = (props: Props) => {
  const {
    leftPanelData,
    leftPanelIcon,
    leftPanelSubtitle,
    leftPanelTitle,
    leftPanelDisableAdding,
    onLeftPanelChange,
    onRightPanelChange,
    rightPanelData,
    rightPanelIcon,
    rightPanelSubtitle,
    rightPanelTitle,
    leftPanelExternalSearch,
    setLeftPanelExternalSearch,
  } = props;

  const [leftPanelSearch, setLeftPanelSearch] = useState<string>('');
  const [rightPanelSearch, setRightPanelSearch] = useState<string>('');
  const isViewMode: boolean = leftPanelData === undefined;

  // We either use external provided search or internal one
  const leftSearchQuery = leftPanelExternalSearch || leftPanelSearch;

  // We either set the left panel search to the external one or the internal one
  const handleLeftSearchChange = (value: string) => {
    if (setLeftPanelExternalSearch) {
      setLeftPanelExternalSearch(value);
    } else {
      setLeftPanelSearch(value);
    }
  };

  // Filter Left Panel Data
  let filteredLeftPanelData = Object.assign([], leftPanelData);
  if (leftSearchQuery.length > 0) {
    filteredLeftPanelData = filteredLeftPanelData.filter(
      (data: TPanelDataItem) =>
        data.name.toLowerCase().indexOf(leftSearchQuery.toLowerCase()) > -1 ||
        data.description?.toLowerCase().indexOf(leftSearchQuery.toLowerCase()) > -1,
    );
  }
  if (filteredLeftPanelData.length > LEFT_PANEL_DATA_ITEM_LIMIT) {
    filteredLeftPanelData = filteredLeftPanelData.slice(0, LEFT_PANEL_DATA_ITEM_LIMIT);
  }
  filteredLeftPanelData = filteredLeftPanelData.sort((a: TPanelDataItem, b: TPanelDataItem) =>
    a.name.localeCompare(b.name),
  );

  // Filter right panel data
  let filteredRightPanelData = Object.assign([], rightPanelData);
  if (rightPanelSearch.length > 0) {
    filteredRightPanelData = filteredRightPanelData.filter(
      (data: TPanelDataItem) =>
        data.name.toLowerCase().indexOf(rightPanelSearch.toLowerCase()) > -1 ||
        data.description?.toLowerCase().indexOf(rightPanelSearch.toLowerCase()) > -1,
    );
  }
  if (filteredRightPanelData.length > RIGHT_PANEL_DATA_ITEM_LIMIT) {
    filteredRightPanelData = filteredRightPanelData.slice(0, RIGHT_PANEL_DATA_ITEM_LIMIT);
  }
  filteredRightPanelData = filteredRightPanelData.sort((a: TPanelDataItem, b: TPanelDataItem) =>
    a.name.localeCompare(b.name),
  );

  return (
    <>
      <Row align="top" justify="space-between" gutter={12}>
        {/* Left Panel */}
        {!isViewMode && (
          <Col span={12}>
            <Section
              title={leftPanelTitle}
              subtitle={leftPanelSubtitle || undefined}
              icon={leftPanelIcon || null}
              rightElement={
                <InputGroup
                  type="search"
                  leftIcon="search"
                  placeholder="Search"
                  disabled={!leftPanelData?.length}
                  intent={leftSearchQuery.length > 0 ? 'primary' : 'none'}
                />
              }
              onChange={(e: any) => handleLeftSearchChange(e.target.value)}
            >
              {leftPanelData?.length === 0 && (
                <Row align="middle">
                  <Col span={24} style={{ paddingTop: '35%' }}>
                    <NonIdealState title="No Data" icon="search" />
                  </Col>
                </Row>
              )}
              {leftPanelData?.length! > 0 && (
                <SectionCard
                  padded={false}
                  style={{
                    minHeight: PanelHeightCSS,
                    maxHeight: PanelHeightCSS,
                    overflowY: 'auto',
                  }}
                >
                  <CardList bordered compact>
                    {filteredLeftPanelData.map((data: TPanelDataItem, i: number) => (
                      <Card key={`card${data.id}`} className="leftDataCard" interactive={false}>
                        <Row key={`row1${data.id}`} align="middle" style={{ width: '100%' }}>
                          <Col key={`col1${data.id}`} span={21}>
                            <Row key={`row2${data.id}`}>
                              <Col key={`col2${data.id}`} span={24}>
                                <span key={`span1${data.id}`} className="name">
                                  {data.name}
                                </span>
                              </Col>
                              <Col key={`col3${data.id}`} span={24}>
                                <span key={`span2${data.id}`} className="description">
                                  {data.description}{' '}
                                </span>
                              </Col>
                              {data.status && (
                                <Col key={`col4${data.id}`} span={24} style={{ marginTop: 3 }}>
                                  <span key={`span2${data.id}`} className="status">
                                    <Tag
                                      minimal
                                      style={{
                                        margin: 0,
                                        fontSize: '0.7em',
                                        padding: '0 6px',
                                      }}
                                      round
                                      intent={data.status === 'ACTIVE' ? 'success' : 'warning'}
                                    >
                                      {data.status}
                                    </Tag>
                                  </span>
                                </Col>
                              )}
                            </Row>
                          </Col>
                          <Col span={3} key={`col4${data.id}`} style={{ textAlign: 'right' }}>
                            <Button
                              key={`button1${data.id}`}
                              // text="Add"
                              icon="plus"
                              minimal
                              disabled={leftPanelDisableAdding}
                              intent="primary"
                              onClick={() => onLeftPanelChange(data.id)}
                            />
                          </Col>
                        </Row>
                      </Card>
                    ))}
                  </CardList>
                </SectionCard>
              )}
            </Section>
          </Col>
        )}
        {/* Right Panel */}
        <Col span={isViewMode ? 24 : 12}>
          <Section
            title={rightPanelTitle}
            subtitle={rightPanelSubtitle || undefined}
            icon={rightPanelIcon || null}
            rightElement={
              <InputGroup
                type="search"
                leftIcon="search"
                placeholder="Search"
                disabled={!rightPanelData.length}
                intent={rightPanelSearch.length > 0 ? 'primary' : 'none'}
              />
            }
            onChange={(e: any) => setRightPanelSearch(e.target.value)}
          >
            <SectionCard
              padded={false}
              style={{
                minHeight: PanelHeightCSS,
                maxHeight: PanelHeightCSS,
                overflowY: 'auto',
              }}
            >
              {rightPanelData.length === 0 && (
                <Row align="middle">
                  <Col span={24} style={{ paddingTop: '35%' }}>
                    <NonIdealState title="No Data" icon="search" />
                  </Col>
                </Row>
              )}
              {rightPanelData.length > 0 && (
                <CardList bordered compact>
                  {filteredRightPanelData.map((data: TPanelDataItem, i: number) => (
                    <Card key={`card${data.id}`} className="leftDataCard" interactive={false}>
                      <Row key={`row1${data.id}`} align="middle" style={{ width: '100%' }}>
                        <Col key={`col1${data.id}`} span={isViewMode ? 24 : 21}>
                          <Row key={`row2${data.id}`}>
                            <Col key={`col2${data.id}`} span={24}>
                              {props.rightPanelLink ? (
                                <a key={`span1${data.id}`} className="name" href={`${props.rightPanelLink}/${data.id}`}>
                                  {data.name}
                                </a>
                              ) : (
                                <span key={`span1${data.id}`} className="name">
                                  {data.name}
                                </span>
                              )}
                            </Col>
                            <Col key={`col3${data.id}`} span={24}>
                              <span key={`span2${data.id}`} className="description">
                                {data.description}{' '}
                              </span>
                            </Col>
                            {data.status && (
                              <Col key={`col4${data.id}`} span={24} style={{ marginTop: 3 }}>
                                <span key={`span2${data.id}`} className="status">
                                  <Tag
                                    minimal
                                    style={{
                                      margin: 0,
                                      fontSize: '0.7em',
                                      padding: '0 6px',
                                    }}
                                    round
                                    intent={data.status === 'ACTIVE' ? 'success' : 'warning'}
                                  >
                                    {data.status}
                                  </Tag>
                                </span>
                              </Col>
                            )}
                          </Row>
                        </Col>
                        {!isViewMode && (
                          <Col span={3} key={`col4${data.id}`} style={{ textAlign: 'right' }}>
                            <Button
                              key={`button1${data.id}`}
                              icon="cross"
                              minimal
                              intent="danger"
                              onClick={() => onRightPanelChange(data.id)}
                            />
                          </Col>
                        )}
                      </Row>
                    </Card>
                  ))}
                  {
                    // Show a message if the list is truncated
                    filteredRightPanelData.length === RIGHT_PANEL_DATA_ITEM_LIMIT && (
                      <Card key="cardTruncated" className="leftDataCard" interactive={false}>
                        <Row key="rowTruncated" align="middle" style={{ width: '100%' }}>
                          <Col key="colTruncated" span={19}>
                            <span key="spanTruncated" className="name">
                              ... and {rightPanelData.length - RIGHT_PANEL_DATA_ITEM_LIMIT} more
                            </span>
                            <br />
                            <span className="description">
                              In order to navigate through long lists, use search input on the top
                              to find what you're looking for, or export the entire list in CSV
                              format.
                            </span>
                          </Col>
                          <Col span={5} style={{ textAlign: 'right' }}>
                            <CSVLink
                              data={rightPanelData}
                              filename={`${rightPanelTitle}-${leftPanelTitle}.csv`}
                            >
                              <Button text="Export" icon="download" intent="success" minimal />
                            </CSVLink>
                          </Col>
                        </Row>
                      </Card>
                    )
                  }
                </CardList>
              )}
            </SectionCard>
          </Section>
        </Col>
      </Row>
    </>
  );
};
