import { Button, Icon } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React from 'react';
import './styles.scss';

interface Props {
  note: any[]
}

const MyCasesUsersNote: React.FC<Props> = (props: Props) => {
  // Replace this dummy data with real data from the state
  const dummyData = {
    content:
      'Est unde tenetur sed dolore voluptatem et nesciunt deleniti ea repellendus odit? Rem temporibus rerum aut facilis cupiditate?',
    user: 'Lily Williams',
    timestamp: '14/08/2024 09:55',
  };

  if (props.note.length === 0) return null;

  return (
    <Row justify="end" style={{ padding: 15 }}>
      <Col span={22} className="myCasesUsersNote">
        <Row>
          {/* Note Title & Toolbar */}
          <Col span={12}>
            <Icon icon="document" style={{ marginRight: 7 }} />
            <span style={{ fontWeight: 600 }}>Note</span>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button small minimal icon="edit" />
            <Button small minimal icon="link" />
          </Col>
          {/* Note Contents */}
          <Col span={24} style={{ paddingTop: 10 }}>
            <span style={{ fontWeight: 500 }}>{dummyData.content}</span>
          </Col>

          {/* Note User name and timestamp  */}
          <Col span={12} style={{ paddingTop: 10 }}>
            <span style={{ color: '#545A5D' }}>{dummyData.user}</span>
          </Col>
          <Col span={12} style={{ paddingTop: 10, textAlign: 'right' }}>
            <span style={{ color: '#545A5D' }}>{dummyData.timestamp}</span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default MyCasesUsersNote;
