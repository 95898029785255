import {
  MY_CASES_SET_ACTIVE_TAB,
  MY_CASES_SET_CASES,
  MY_CASES_SET_SELECTED_CASE,
  MY_CASES_TOGGLE_CONTACT_DETAILS_PANEL,
  MY_CASES_TOGGLE_NOTE_EDITOR,
} from './constants';
import { dummyAllCases } from './dummyCases';

export interface TConversation {
  id: string;
  entity: string;
  type: string;
  schemaTypeId: string;
  schemaPosition: number;
  schemaId: string;
  schemaAssociationId: string;
  externalId: string;
  title: string;
  recordNumber: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  stageUpdatedAt: string;
  createdBy: CreatedBy;
  lastModifiedBy: LastModifiedBy;
  ownedBy: string;
  properties: Properties;
  formattedProperties: FormattedProperties;
  dbRecordAssociation: DbRecordAssociation;
  groups: Group[];
  isArchived: boolean;
  links: any[];
  isLocked: boolean;
}

export interface CreatedBy {
  id: string;
  fullName: string;
}

export interface LastModifiedBy {
  id: string;
  fullName: string;
}

export interface Properties {
  Channel: string;
  ExternalId: string;
  Source: string;
  Status: string;
}

export interface FormattedProperties {}

export interface DbRecordAssociation {
  id: string;
  linkType: string;
  relatedAssociationId: any;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  isLocked: boolean;
  relationType: string;
}

export interface Group {
  id: string;
  name: string;
}

export interface TMessage {
  id: string;
  properties: MessageProperties;
  hourSent: string;
  dateSent: string;
}

export interface MessageProperties {
  Body: string;
  ErrorMessage: string;
  ExternalId: string;
  From: string;
  Status: string;
  To: string;
}

// This type is used for development, update when real data becomes available.
export type TCase = {
  recordNumber: string;
  id: string;
  title: string;
  status: 'OPEN' | 'CLOSED' | 'PENDING' | 'ON_HOLD' | 'SOLVED';
  time: string;
  type: 'CALL' | 'SMS' | 'EMAIL';
  contact: string;
  unread: boolean;
  assigned?: string;
  category?: string;
};

export interface IMyCasesReducer {
  cases: TCase[];
  isAddingNote: boolean;
  isContactPanelOpen: boolean;
  isLoadingCase: boolean;
  isLoadingCases: boolean;
  isUpdatingCase: boolean;
  selectedCase?: TCase | undefined;
  selectedCasesTabId: 'my-queue' | 'following';
}

export const MyCasesReducerInitialState: IMyCasesReducer = {
  cases: dummyAllCases, // <-- dummy data
  isAddingNote: false,
  isContactPanelOpen: false,
  isLoadingCase: false,
  isLoadingCases: false,
  isUpdatingCase: false,
  selectedCase: dummyAllCases[0], // <-- dummy data
  selectedCasesTabId: 'my-queue',
};

export function myCasesReducer(state: IMyCasesReducer, action: { type: string; payload: any }) {
  switch (action.type) {
    case MY_CASES_SET_CASES:
      return { ...state, cases: action.payload };
    case MY_CASES_SET_SELECTED_CASE:
      return { ...state, selectedCase: action.payload };
    case MY_CASES_TOGGLE_NOTE_EDITOR:
      return { ...state, isAddingNote: !state.isAddingNote };
    case MY_CASES_TOGGLE_CONTACT_DETAILS_PANEL:
      return { ...state, isContactPanelOpen: !state.isContactPanelOpen };
    case MY_CASES_SET_ACTIVE_TAB:
      return { ...state, selectedCasesTabId: action.payload };
    default:
      throw new Error();
  }
}
