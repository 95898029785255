import { Col, Row } from 'antd';
import React from 'react';

interface Props {
  name: string;
  timestamp: string;
}

const MyCasesClientNameTimestamp: React.FC<Props> = (props: Props) => {
  const { name, timestamp } = props;
  return (
    <Col span={22} style={{ color: '#727c93' }}>
      <Row justify="space-between">
        <Col>
          <span>{name}</span>
        </Col>
        <Col>
          <span>{timestamp}</span>
        </Col>
      </Row>
    </Col>
  );
};
export default MyCasesClientNameTimestamp;
