import { Button, EntityTitle } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { MyCasesContext } from '../..';
import { dummyHistoryCases } from '../../store/dummyCases';
import CommunicationsIcon from '../CommunicationsIcon';
import CommunicationsStatusTag from '../CommunicationsStatusTag';

interface Props {}

const sanitizeMessageTitle = (title: string) => {
  const maxLength = 24;
  if (title.length > maxLength) {
    return `${title.slice(0, maxLength)}...`;
  } else {
    return title;
  }
};

// During development we would use dummy data, so cases that were not OPEN we would render
// differently. This might differ when we have real data, so we would need to update this.
const CommunicationsHistory: React.FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(MyCasesContext);

  const renderCases = () => {
    return dummyHistoryCases.map((c, i) => {
      return (
        <>
          <Col span={1}>
            <Row align="middle" style={{ height: '100%' }}>
              {/* Top Vertical Line */}
              <Col span={24} style={{ height: '39%', textAlign: 'center' }}>
                <div
                  style={{
                    borderLeft: i === 0 ? 'none' : '1px dashed #C0C4C7',
                    height: '100%',
                    marginLeft: 3,
                  }}
                />
              </Col>

              {/* Bullet */}
              <Col span={24} style={{ height: '6%', textAlign: 'center' }}>
                <div
                  style={{
                    height: 7,
                    width: 7,
                    border: '1px solid',
                    borderColor: c.status === 'CLOSED' ? '#C0C4C7' : 'black',
                    background: c.status === 'CLOSED' ? 'white' : 'black',
                    borderRadius: 50,
                  }}
                />
              </Col>

              {/* Bottom Vertical Line */}
              <Col
                span={24}
                style={{
                  height: '45%',
                  textAlign: 'center',
                }}
              >
                <div
                  style={{
                    marginLeft: 3,
                    borderLeft: i === dummyHistoryCases.length - 1 ? 'none' : '1px dashed #C0C4C7',
                    height: '100%',
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col
            span={23}
            key={i}
            style={{
              border: c.status !== 'CLOSED' ? '1px solid #E6E7E8' : '1px solid white',
              borderRadius: 10,
              marginBottom: i !== dummyHistoryCases.length - 1 ? 10 : 0,
            }}
          >
            <Row>
              <Col span={24} style={{ padding: 10 }}>
                <Row justify="space-between">
                  <Col>
                    <EntityTitle
                      title={sanitizeMessageTitle(c.title)}
                      subtitle={c.contact}
                      icon={<CommunicationsIcon small type={c.type} />}
                    />
                  </Col>
                  <Col>
                    <CommunicationsStatusTag status={c.status} />
                  </Col>
                </Row>
              </Col>
              {c.status !== 'CLOSED' && (
                <Col span={24} style={{ borderTop: '1px solid #E6E7E8', padding: 10 }}>
                  <Row>
                    <Col span={18}>
                      <Row>
                        <Col span={24}>
                          <span style={{ opacity: 0.5, fontSize: '0.9em' }}>Assigned:</span>{' '}
                          <span style={{ fontSize: '0.9em' }}>{c.assigned}</span>
                        </Col>
                        <Col span={24}>
                          <span style={{ opacity: 0.5, fontSize: '0.9em' }}>Category:</span>{' '}
                          <span style={{ fontSize: '0.9em' }}>{c.category || 'Unknown'}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}>
                      <Button outlined small style={{ borderRadius: 50 }}>
                        View
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </>
      );
    });
  };

  return <Row style={{ paddingRight: 5 }}>{renderCases()}</Row>;
};
export default CommunicationsHistory;
