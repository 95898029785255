import { Button, Section, TextArea } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { MyCasesContext } from '../..';
import { MY_CASES_TOGGLE_NOTE_EDITOR } from '../../store/constants';
import './styles.scss';

interface Props {}

const MyCasesNoteEditorWidget: React.FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(MyCasesContext);

  const [newNoteText, setNewNoteText] = useState<string>('');

  const toggleNoteEditor = () => {
    dispatch({ type: MY_CASES_TOGGLE_NOTE_EDITOR, payload: null });
    setNewNoteText('');
  };

  return (
    <Row style={{ padding: 15 }} justify="end">
      <Col>
        <Button
          text="Add Internal Comment"
          icon="plus"
          disabled={state.isAddingNote}
          onClick={toggleNoteEditor}
          minimal
          intent="primary"
          style={{ boxShadow: '0px 0px 12px 0px rgba(0,0,0,0.10)' }}
        />
      </Col>

      {/* Internal Comment editor */}
      {state.isAddingNote && (
        <div className="newNoteContainer">
          <Section
            compact
            className="newNoteSection"
            title="Edit Note"
            icon="document"
            rightElement={<Button minimal icon="cross" onClick={toggleNoteEditor} />}
          >
            <div
              style={{
                background: 'white',
                height: 'calc(100vh - 593px)',
                overflowY: 'auto',
                padding: 5,
              }}
            >
              <TextArea
                placeholder="Write a note"
                fill
                autoFocus
                value={newNoteText}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setNewNoteText(e.target.value)
                }
                style={{ height: '100%', boxShadow: 'none' }}
              />
            </div>
            <div
              style={{
                background: 'white',
                borderTop: '1px solid #D9DADA',
              }}
            >
              <Row
                justify="space-between"
                style={{
                  padding: 10,
                  height: 50,
                }}
                align="middle"
              >
                <Col>
                  <Button icon="paperclip" className="newNoteSectionAttachIcon" minimal />
                </Col>
                <Col>
                  <Button
                    intent="danger"
                    outlined
                    text="Delete"
                    style={{ marginRight: 8 }}
                    onClick={() => setNewNoteText('')}
                  />
                  <Button intent="primary" text="Save" />
                </Col>
              </Row>
            </div>
          </Section>
        </div>
      )}
    </Row>
  );
};
export default MyCasesNoteEditorWidget;
