import { EntityTitle } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { MyCasesContext } from '../../..';
import { MY_CASES_SET_SELECTED_CASE } from '../../../store/constants';
import { TCase } from '../../../store/reducer';
import CommunicationsBadge from '../../CommunicationsBadge';
import CommunicationsIcon from '../../CommunicationsIcon';
import CommunicationsStatusTag from '../../CommunicationsStatusTag';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

interface Props {
  case: TCase;
  index: number;
  setNewMessageCounters: React.Dispatch<React.SetStateAction<never[]>>;
}

const MyCasesListItem: React.FC<Props> = (props: Props) => {
  const { index } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const sanitizeMessageTitle = (title: string) => {
    const maxLength = 18;
    if (title.length > maxLength) {
      return `${title.slice(0, maxLength)}...`;
    } else {
      return title;
    }
  };

  const handleCaseClick = () => {
    if (!isCaseSelected()) {
      dispatch({ type: MY_CASES_SET_SELECTED_CASE, payload: props.case });
    }


    props.setNewMessageCounters((prevCounters: any) => {
      return prevCounters.filter((caseCounter: any) => caseCounter.caseId !== props.case.id);
    });
  };

  const isCaseSelected = () => {
    return state.selectedCase && state.selectedCase.id === props.case.id;
  };

  return (
    <Col span={24} key={`${props.case.id}_${index}`}>
      {console.log(`${props.case.id}_${index}`)}
      <Row
        onClick={handleCaseClick}
        style={{
          cursor: 'pointer',
          padding: '15px 8px',
          borderLeft: isCaseSelected() ? '3px solid blue' : '3px solid #fff',
          borderTop: index > 0 ? '1px solid #D9DADA' : 'auto',
        }}
      >
        <Col span={1}>
          <CommunicationsBadge unread={props.case.unread} />
        </Col>
        <Col span={17} style={{ paddingLeft: 5 }}>
          <EntityTitle
            title={sanitizeMessageTitle(props.case.title)}
            subtitle={props.case.contact}
            icon={<CommunicationsIcon type={props.case.type} />}
          />
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Row>
            <Col span={24}>
              <CommunicationsStatusTag status={getProperty(props.case as any, 'Status')} />
            </Col>
            <Col span={24} style={{ paddingRight: 4 }}>
              <span style={{ fontSize: 10 }}>
                {dayjs((props.case as any).createdAt as any).format('HH:mm')}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default MyCasesListItem;
