import { TCase } from './reducer';

export const dummyAllCases: TCase[] = [
  {
    recordNumber: '123',
    id: '1',
    title: 'About my order',
    status: 'OPEN',
    time: '2024-09-01T12:26:00',
    type: 'CALL',
    contact: 'Jared Black',
    unread: true,
    assigned: 'J. Smith',
  },
  {
    recordNumber: '234',
    id: '2',
    title: 'Order question',
    status: 'OPEN',
    time: '2024-09-01T12:26:00',
    type: 'SMS',
    contact: 'Jared Black',
    unread: false,
    assigned: 'D. Jackson',
    category: 'Billing > Payments',
  },
  {
    recordNumber: '345',
    id: '3',
    title: 'My router issue',
    status: 'OPEN',
    time: '2024-09-01T12:20:00',
    type: 'CALL',
    contact: 'Ann Dove',
    unread: true,
  },
  {
    recordNumber: '456',
    id: '4',
    title: 'Payment confirmation',
    status: 'OPEN',
    time: '2024-09-01T12:20:00',
    type: 'EMAIL',
    contact: 'Ian Hicks',
    unread: true,
  },
  {
    recordNumber: '567',
    id: '5',
    title: "Can't get into my apartment",
    status: 'OPEN',
    time: '2024-09-01T11:10:00',
    type: 'CALL',
    contact: 'Mike Doors',
    unread: false,
  },
  {
    recordNumber: '678',
    id: '6',
    title: 'What do I do about my WiFi mesh',
    status: 'ON_HOLD',
    time: '2024-08-30T11:01:00',
    type: 'SMS',
    contact: 'Diana Moon',
    unread: false,
  },
  {
    recordNumber: '789',
    id: '7',
    title: 'Router Installation',
    status: 'PENDING',
    time: '2024-08-25T14:31:00',
    type: 'CALL',
    contact: 'Joe Passalacqua',
    unread: false,
  },
  {
    recordNumber: '890',
    id: '8',
    title: 'Bad Router',
    status: 'PENDING',
    time: '2024-08-20T09:12:00',
    type: 'CALL',
    contact: 'Mike Modem',
    unread: false,
  },
  {
    recordNumber: '901',
    id: '9',
    title: 'Weird noise coming from the Router',
    status: 'PENDING',
    time: '2024-08-20T19:12:00',
    type: 'SMS',
    contact: 'Boe Joe',
    unread: false,
  },
  {
    recordNumber: '100',
    id: '10',
    title: 'Bad Modem',
    status: 'SOLVED',
    time: '2024-08-18T19:10:00',
    type: 'EMAIL',
    contact: 'Silly Billy',
    unread: false,
  },
];

export const dummyHistoryCases: TCase[] = [
  {
    recordNumber: '10',
    id: '10',
    title: 'Issue: Incorrect Account Information',
    status: 'ON_HOLD',
    time: '2024-09-01T12:26:00',
    type: 'SMS',
    contact: 'Jared Black',
    unread: true,
    assigned: 'J. Smith',
  },
  {
    recordNumber: '11',
    id: '11',
    title: 'Issue: Billing',
    status: 'PENDING',
    time: '2024-09-01T12:26:00',
    type: 'SMS',
    contact: 'Jared Black',
    unread: false,
    assigned: 'D. Jackson',
    category: 'Billing > Payments',
  },
  {
    recordNumber: '12',
    id: '12',
    title: 'My router issue',
    status: 'CLOSED',
    time: '2024-09-01T12:20:00',
    type: 'CALL',
    contact: 'Ann Dove',
    unread: true,
  },
  {
    recordNumber: '13',
    id: '13',
    title: 'Payment confirmation',
    status: 'CLOSED',
    time: '2024-09-01T12:20:00',
    type: 'EMAIL',
    contact: 'Ian Hicks',
    unread: true,
  },
  {
    recordNumber: '14',
    id: '14',
    title: 'Payment question',
    status: 'CLOSED',
    time: '2024-04-01T11:20:00',
    type: 'CALL',
    contact: 'Ian Hicks',
    unread: true,
  },
];
