import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { MyCasesContext } from '../..';
import { TCase } from '../../store/reducer';
import MyCasesListItem from './MyCaseListItem';
import './styles.scss';
import { io } from 'socket.io-client';
import { CaseApi } from '@d19n/sandbox-odin-sdk/dist/api-sdk-v1/CaseApi';

interface Props {
  setMessages: (value: React.SetStateAction<any[]>) => void;
  setNewMessageCounters: React.Dispatch<React.SetStateAction<never[]>>;
  setMyCases: React.Dispatch<React.SetStateAction<TCase[]>>;
  myCases: TCase[];
  userReducer: any;
  // handleCaseClick: (caseId: string) => void;
}

const SOCKET_URL = `${import.meta.env.VITE_ODIN_WEBSOCKET_URL}/ChatModule/TwilioChatWebsocket/v1.0`;
const socket = io(SOCKET_URL, {
  path: '/ws/socket.io',
});

const MyCasesList: React.FC<Props> = ({
  setMessages,
  setNewMessageCounters,
  setMyCases,
  myCases,
  userReducer,
}: Props) => {
  const { state } = useContext(MyCasesContext);

  const caseClient = new CaseApi(
    {
      host: 'api.sandbox.odinfusion.com',
      clientId: '',
      clientSecret: '',
    },
    localStorage.getItem('token') as string,
  );

  useEffect(() => {
    socket.on('connect', () => console.log('Connected to WebSocket server'));
    socket.on('message-sent', handleIncomingMessage);
    socket.on('end-conversation', handleEndConversation);

    return () => {
      socket.off('message-sent', handleIncomingMessage);
      socket.off('end-conversation', handleEndConversation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedCase]);

  const handleIncomingMessage = async (message: any) => {
    if (message.caseId === state.selectedCase?.id) {
      setMessages((prevMessages: any) => [
        ...prevMessages,
        {
          id: message.messageId,
          properties: {
            From: message.from,
            Body: message.message,
          },
          hourSent: message.hourSent,
          dateSent: message.dateSent,
        },
      ]);
    } else {
      setNewMessageCounters((prevCounters: any) => {
        const existingCase = prevCounters.find(
          (caseCounter: any) => caseCounter.caseId === message.caseId,
        );

        if (existingCase) {
          return prevCounters.map((caseCounter: any) =>
            caseCounter.caseId === message.caseId
              ? { ...caseCounter, counter: caseCounter.counter + 1 }
              : caseCounter,
          );
        } else {
          return [...prevCounters, { caseId: message.caseId, counter: 1 }];
        }
      });
    }
  };

  const handleEndConversation = async (data: any) => {
    console.log(`Conversation ended: ${data.conversationSid}`);
  };

  const fetchMyCases = async () => {
    try {
      const myCases = await caseClient.searchAllRecordsAndPaginate({
        query: {
          entity: 'infer',
          type: 'and',
          value: [
            {
              columnName: 'OwnerId',
              operator: 'eq',
              value: userReducer.user.id,
            },
          ],
          returnProperties: ['*'],
          sort: {
            createdAt: {
              order: 'asc',
            },
          },
          pageSize: 1000,
          pageNumber: 0,
        },
      });
      if (myCases.records) {
        const recordsWithAdditionalInfo = await Promise.all(
          myCases.records.map(async (record) => {
            const additionalInfo = await caseClient.getConversations(record.id);
            if (additionalInfo.length > 0) {
              return {
                ...record,
                conversationInfo: additionalInfo[0].properties,
              };
            } else {
              return {
                ...record,
              };
            }
          }),
        );
        setMyCases(recordsWithAdditionalInfo);
      }
    } catch (e) {
      console.log('Error on myCases --> ', e);
      console.error(e);
    }
  };

  useEffect(() => {
    fetchMyCases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReducer.user?.id]);

  // Replace this with cases data, filtered by the case status
  const priorityCases: TCase[] = myCases.slice(0, 2);
  const conversations: TCase[] = myCases.slice(2, myCases.length - 1);
  const solved = myCases.slice(myCases.length - 1, myCases.length);

  return (
    <Row className="myCasesListRow" align="top">
      <div>
        <Row className="prioritySection">
          {/* Priority  */}
          <Col span={24} style={{ padding: '5px 10px', background: '#f3f5fd' }}>
            <h4 style={{ color: '#123ee0' }}>Priority</h4>
          </Col>
          <Col span={24} style={{}}>
            <Row justify="space-between">
              {priorityCases.map((item: TCase, index: number) => (
                <MyCasesListItem
                  case={item}
                  index={index}
                  setNewMessageCounters={setNewMessageCounters}
                />
              ))}
            </Row>
          </Col>

          {/* Conversations */}
          <Col span={24} style={{ padding: '5px 10px', background: '#F8F8F8' }}>
            <h4 style={{ color: 'black' }}>Conversations</h4>
          </Col>
          <Col span={24}>
            <Row justify="space-between">
              {conversations.map((item: TCase, index: number) => (
                <MyCasesListItem
                  case={item}
                  index={index}
                  setNewMessageCounters={setNewMessageCounters}
                />
              ))}
            </Row>
          </Col>

          {/* Solved */}
          <Col span={24} style={{ padding: '5px 10px', background: '#F8F8F8' }}>
            <h4 style={{ color: '#545A5D', fontWeight: 400 }}>Solved</h4>
          </Col>
          <Col span={24}>
            <Row justify="space-between">
              {solved.map((item: TCase, index: number) => (
                <MyCasesListItem
                  case={item}
                  index={index}
                  setNewMessageCounters={setNewMessageCounters}
                />
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    </Row>
  );
};
export default MyCasesList;
