import { Button } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { MyCasesContext } from '../..';
import { MY_CASES_TOGGLE_CONTACT_DETAILS_PANEL } from '../../store/constants';
import CommunicationsHistory from '../CommunicationsHistory';
import './styles.scss';

interface Props {}

const MyCasesContactDetails: React.FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(MyCasesContext);

  const togglePanel = () => {
    dispatch({ type: MY_CASES_TOGGLE_CONTACT_DETAILS_PANEL, payload: null });
  };

  // Replace this with real user data from the state
  const dummyUserData = {
    initials: 'JB',
    name: 'Jared Black',
    status: 'Active',
    statusColor: '#008949',
    workOrderStep: 'Open Order',
    phoneNumber: '801-549-3492',
    email: 'jblack@gmail.com',
  };

  return (
    <Col span={state.isContactPanelOpen ? 1 : 7} className="myCasesContactDetailsCol">
      <Row style={{ padding: state.isContactPanelOpen ? 7 : 10 }}>
        <Col span={24}>
          <Row justify={state.isContactPanelOpen ? 'center' : 'space-between'}>
            <Col>
              <Button
                icon={state.isContactPanelOpen ? 'drawer-right' : 'drawer-left'}
                text={state.isContactPanelOpen ? '' : 'Hide'}
                minimal
                intent="primary"
                onClick={togglePanel}
              />
            </Col>
            {!state.isContactPanelOpen && (
              <Col>
                <Button minimal icon="share" intent="primary" />
              </Col>
            )}
          </Row>
        </Col>
        <Col span={24} style={{ height: 'calc(100vh - 230px)', overflowY: 'auto' }}>
          <Row style={{ marginTop: 5 }}>
            <Col
              span={24}
              style={{ marginTop: 10, textAlign: state.isContactPanelOpen ? 'center' : 'left' }}
            >
              {/* Avatar */}
              <div
                style={{
                  display: 'inline-block',
                  background: '#EFEFEF',
                  padding: 6,
                  fontSize: '1.1em',
                  height: 32,
                  width: 32,
                  borderRadius: 50,
                }}
              >
                <span>{dummyUserData.initials}</span>
              </div>
              {!state.isContactPanelOpen && (
                <span
                  style={{
                    marginLeft: 8,
                    lineHeight: 0,
                    fontSize: '1.3em',
                    fontWeight: 600,
                  }}
                >
                  {dummyUserData.name}
                </span>
              )}
            </Col>

            {/* Personal Information + Communications History */}
            {!state.isContactPanelOpen && (
              <>
                <Col span={24} style={{ marginTop: 30 }}>
                  <span style={{ fontWeight: 700 }}>Personal Information</span>
                </Col>

                {/* Customer Status / WO Step */}
                <Col span={12} style={{ marginTop: 15 }}>
                  <span style={{ opacity: 0.5 }}>Customer Status</span>
                </Col>
                <Col span={12} style={{ marginTop: 15 }}>
                  <span style={{ opacity: 0.5 }}>Work Order Step</span>
                </Col>
                <Col span={12} style={{ marginTop: 5 }}>
                  <span style={{ fontWeight: 600, color: dummyUserData.statusColor }}>
                    {dummyUserData.status}
                  </span>
                </Col>
                <Col span={12} style={{ marginTop: 5 }}>
                  <span>{dummyUserData.workOrderStep}</span>
                </Col>

                {/* Phone Number / Email */}
                <Col span={12} style={{ marginTop: 15 }}>
                  <span style={{ opacity: 0.5 }}>Phone Number</span>
                </Col>
                <Col span={12} style={{ marginTop: 15 }}>
                  <span style={{ opacity: 0.5 }}>Email</span>
                </Col>
                <Col span={12} style={{ marginTop: 5 }}>
                  <span>{dummyUserData.phoneNumber}</span>
                </Col>
                <Col span={12} style={{ marginTop: 5 }}>
                  <span>{dummyUserData.email}</span>
                </Col>

                {/* History */}
                <Col span={24} style={{ marginTop: 30 }}>
                  <span style={{ fontWeight: 700 }}>History</span>
                </Col>
                <Col span={24} style={{ paddingTop: 15 }}>
                  <CommunicationsHistory />
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
export default MyCasesContactDetails;
