import { Button, InputGroup } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { MyCasesContext } from '../..';
import MyCasesNoteEditorWidget from '../MyCasesNoteEditorWidget';
import MyCasesClientMessage from './MyCasesClientMessage';
import MyCasesClientNameTimestamp from './MyCasesClientNameTimestamp';
import MyCasesUserMessage from './MyCasesUserMessage';
import MyCasesUserTimestamp from './MyCasesUserTimestamp';
import MyCasesUsersNote from './MyCasesUsersNote';
import './styles.scss';
import { httpGet } from '../../../../../../../shared/http/requests';
import { connect } from 'react-redux';
import { TConversation, TMessage } from '../../store/reducer';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import dayjs from 'dayjs';

interface Props {
  userReducer: any;
}

const MyCasesConversationFeed: React.FC<Props> = (props: Props) => {
  const { userReducer } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const [messageText, setMessageText] = useState<string>('');

  // Case Activity
  const [activity, setCaseActvity] = useState([]);
  const [conversations, setConversations] = useState<TConversation>();
  const [messages, setMessages] = useState<TMessage[]>([]);
  const [notes, setNotes] = useState<any[]>([]);
  const [emails, setEmails] = useState([]);

  const handleCaseActivity = async (caseId: string) => {
    try {
      const activity = await httpGet(`ChatModule/v1.0/case/${caseId}/activity`);

      if (activity.data) {
        const conversation = activity.data.data.filter((item: any) => item.type === 'conversation');
        const note = activity.data.data.filter((item: any) => item.type === 'note');
        const email = activity.data.data.filter((item: any) => item.type === 'email');

        setConversations(conversation[0].data[0] || []);
        setNotes(note[0].data || []);
        setEmails(email || []);
      }
      setCaseActvity(activity.data);
    } catch (error) {
      setCaseActvity([]);
    }
  };

  const formatTimestampToAMPM = (timestamp: string): string => {
    // Parse the timestamp
    const date = new Date(`1970-01-01T${timestamp}Z`);

    // Options to format the time without milliseconds
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };

    // Format the time to remove microseconds and add AM/PM
    return date.toLocaleTimeString('en-US', options);
  };

  const fetchMessages = async (conversationId: string) => {
    try {
      const messages = await httpGet(
        `ChatModule/v1.0/gateway/conversation/${conversationId}/messages`,
      );
      if (messages.data) {
        const sortedMessages = [...messages.data.data].sort((a, b) => {
          const dateTimeA = dayjs(
            `${a.dateSent} ${a.hourSent}`,
            'MM/DD/YYYY HH:mm:ss.SSSSSS',
          ).valueOf();
          const dateTimeB = dayjs(
            `${b.dateSent} ${b.hourSent}`,
            'MM/DD/YYYY HH:mm:ss.SSSSSS',
          ).valueOf();

          return dateTimeA - dateTimeB;
        });
        setMessages(sortedMessages || []);
      }
    } catch (error) {
      setMessages([]);
    }
  };

  useEffect(() => {
    if (state.selectedCase) {
      handleCaseActivity(state.selectedCase.id);
    }
  }, [state.selectedCase]);

  useEffect(() => {
    if (conversations) {
      fetchMessages(conversations.id);
    }
  }, [conversations]);

  return (
    <Col
      span={state.isContactPanelOpen ? 23 : 17}
      style={{
        background: 'white',
        height: 'calc(100vh - 180px)',
        borderLeft: '1px solid #D9DADA',
      }}
    >
      {/* Message Header */}
      <Row style={{ padding: '15px 20px', borderBottom: '1px solid #d9dada' }}>
        <Col span={24}>
          <h3 style={{ margin: 0 }}>Email: New Account</h3>
        </Col>
        <Col span={24} style={{ marginTop: 10 }}>
          <div className="myCasesConversationFeedHeader">
            <span>JB</span>
          </div>
          <span style={{ marginLeft: 5, lineHeight: 0 }}>Jared Black</span>
        </Col>
      </Row>

      <Row style={{ backgroundColor: 'white', height: 'calc(100vh - 340px)' }}>
        <Col span={24} style={{ overflowY: 'auto', height: '100%' }}>
          {messages &&
            messages.length > 0 &&
            messages.map((message) => (
              <Row
                justify={`${
                  getProperty(message, 'From') === userReducer.user.email ? 'end' : 'start'
                }`}
                style={{ padding: 15 }}
              >
                {getProperty(message, 'From') !== userReducer.user.email ? (
                  <>
                    <MyCasesClientMessage message={getProperty(message, 'Body')} />
                    <MyCasesClientNameTimestamp
                      name={getProperty(message, 'From') || 'New Account'}
                      timestamp={formatTimestampToAMPM(message.hourSent)}
                    />
                  </>
                ) : (
                  <>
                    <MyCasesUserMessage message={getProperty(message, 'Body')} />
                    <MyCasesUserTimestamp timestamp={formatTimestampToAMPM(message.hourSent)} />
                  </>
                )}
              </Row>
            ))}

          {/* User's Note 1 */}
          <MyCasesUsersNote note={notes} />

          {/* Add Note Button */}
          <MyCasesNoteEditorWidget />
        </Col>
      </Row>

      <Row style={{ borderTop: '1px solid #D9DADA' }} align="middle">
        <Col span={24}>
          <Row style={{ margin: 10 }}>
            <Col span={20}>
              <InputGroup
                style={{ border: 'none', boxShadow: 'none' }}
                large
                type="text"
                placeholder="Chat text"
                value={messageText}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setMessageText(e.target.value)
                }
              />
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              <Button icon="paperclip" minimal large style={{ marginRight: 5 }} />
              <Button intent="primary" text="Send" large />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCasesConversationFeed);
