/* eslint-disable react-hooks/exhaustive-deps */
import { Callout, Card, H5, H6, InputGroup, Spinner, Tab, Tabs, Tag } from '@blueprintjs/core';
import { Chat, Inbox, MobilePhone } from '@blueprintjs/icons';
import { CaseApi } from '@d19n/sandbox-odin-sdk/dist/api-sdk-v1/CaseApi';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Descriptions, Row } from 'antd';
import axios from 'axios';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { io } from 'socket.io-client';
import RecordCard from '../../../../../core/records/components/RecordCard';
import ChatFeed from '../../../../shared/components/ChatFeed';
import ChatTopBar from '../../../../shared/components/ChatFeed/ChatTopBar';
import CustomDrawer from '../../../../shared/components/CustomDrawer/CustomDrawer';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import { EmailFeed } from '../../../../shared/components/EmailFeed';
import HeaderDetailView from '../../../../shared/components/HeaderDetailView';
import FacebookIcon from '../../../../shared/components/Icons/FacebookIcon';
import WhatsappIcon from '../../../../shared/components/Icons/WhatsappIcon';
import NoteFeed from '../../../../shared/components/NoteFeed';
import RecordStageSequentialPipeline from '../../../../shared/components/RecordStageSequentialPipeline';
import SchemaActionPageHeader from '../../../../shared/components/SchemaActions/SchemaActionPageHeader';
import { AppToaster } from '../../../../shared/components/Toaster';
import ConversationDetailsCard from './ConversationDetailsCard';
import './styles.scss';
// import { EmojiPicker } from './emoji-picker';

const SOCKET_URL = `${import.meta.env.VITE_ODIN_WEBSOCKET_URL}/ChatModule/TwilioChatWebsocket/v1.0`;
const socket = io(SOCKET_URL, {
  path: '/ws/socket.io',
});

interface Props {
  userReducer: any;
}

const MyCasesView: FC<Props> = (props: Props) => {
  const { userReducer } = props;
  const textareaRef = useRef(null);
  const participantInputRef = useRef(null);

  const caseClient = new CaseApi(
    {
      host: 'api.sandbox.odinfusion.com',
      clientId: '',
      clientSecret: '',
    },
    localStorage.getItem('token') as string,
  );

  const [myCasesLoading, setMyCasesLoading] = useState<boolean>(false);
  const [myCases, setMyCases] = useState<any[]>([
    { id: 1, title: 'dummy case 1' },
    {
      id: 2,
      title: 'dummy case 2',
    },
  ]);

  const [selectedCaseId, setSelectedCase] = useState<string | undefined>(undefined);
  const [newMessageCounters, setNewMessageCounters] = useState([]);
  const [caseDetailLoading, setCaseDetailLoading] = useState<boolean>(false);
  const [caseDetail, setCaseDetail] = useState<any>(undefined);
  const [conversation, setConversation] = useState<any>(undefined);
  const [messagesLoading, setMessagesLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<any[]>([]);

  const [originalCaseEmail, setOriginalCaseEmail] = useState<DbRecordEntityTransform | undefined>();

  const [message, setMessage] = useState('');
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const [openParticipantDrawer, setOpenParticipantDrawer] = useState(false);
  const [participantNameValue, setParticipantNameValue] = useState('');
  const [isAddParticipantLoading, setIsAddParticipantLoading] = useState(false);
  const [selectedMiddleTab, setSelectedMiddleTab] = useState<string | undefined>(
    !!originalCaseEmail ? 'email-tab' : 'chat-tab',
  );

  const [conversationInfo, setConversationInfo] = React.useState<any>(null);
  const [conversationParticipants, setConversationParticipants] = React.useState([]);

  const [isFetchingConversationInfo, setIsFetchingConversationInfo] = React.useState(false);

  const [showEndConversationAlert, setShowEndConversationAlert] = React.useState(false);

  useEffect(() => {
    fetchMyCases();
  }, [userReducer.user?.id]);

  useEffect(() => {
    fetchCaseDetail();
    fetchCaseConversation();
  }, [selectedCaseId]);

  useEffect(() => {
    if (messages && messages.length) {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
      }
    }
  }, [messages, messages?.length]);

  useEffect(() => {
    socket.on('connect', () => console.log('Connected to WebSocket server'));
    socket.on('message-sent', handleIncomingMessage);
    socket.on('end-conversation', handleEndConversation);

    return () => {
      socket.off('message-sent', handleIncomingMessage);
      socket.off('end-conversation', handleEndConversation);
    };
  }, [selectedCaseId]);

  const fetchMyCases = async () => {
    try {
      setMyCasesLoading(true);
      const queryParams = await getQueryParams(userReducer);
      const myCases = await caseClient.searchAllRecordsAndPaginate(queryParams as any);
      if (myCases.records) {
        const recordsWithAdditionalInfo = await Promise.all(
          myCases.records.map(async (record) => {
            const additionalInfo = await caseClient.getConversations(record.id);
            if (additionalInfo.length > 0) {
              return {
                ...record,
                conversationInfo: additionalInfo[0].properties,
              };
            } else {
              return {
                ...record,
              };
            }
          }),
        );
        setMyCases(recordsWithAdditionalInfo);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setMyCasesLoading(false);
    }
  };

  const fetchCaseDetail = async () => {
    try {
      if (selectedCaseId) {
        setCaseDetailLoading(true);
        const caseDetail = await caseClient.getByPrimaryKey(selectedCaseId);
        setCaseDetail(caseDetail);
        setCaseDetailLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchConversationInformation = async (conversations: any[]) => {
    setIsFetchingConversationInfo(true);
    const fetchConversationInfo = async (conversations: any[]) => {
      if (conversations.length > 0) {
        try {
          const conversationInfo = await axios.request({
            url: `https://api.sandbox.odinfusion.com/ChatModule/v1.0/gateway/conversation/${conversations[0].id}`,
            method: 'get',
            headers: getHeaders(),
          });
          if (conversationInfo.data) {
            setConversationInfo(conversationInfo.data.data);
          }
        } catch (error) {
          AppToaster({ toasterPosition: 'bottom' }).show(
            {
              message: `We could not fetch conversation details, because this is not supported by ${conversations[0].properties.Channel}`,
              intent: 'danger',
            },
            'chat-container',
          );
        }
      }
    };

    const fetchPartcipants = async (conversations: any[]) => {
      if (conversations.length > 0) {
        try {
          const conversationParticipants = await axios.request({
            url: `https://api.sandbox.odinfusion.com/ChatModule/v1.0/gateway/conversation/${conversations[0].id}/participants`,
            method: 'get',
            headers: getHeaders(),
          });
          if (conversationParticipants.data) {
            setConversationParticipants(conversationParticipants.data.data);
          }
        } catch (error) {
          AppToaster({ toasterPosition: 'bottom' }).show(
            {
              message: `We could not fetch conversation participants, because this is not supported by ${conversations[0].properties.Channel}`,
              intent: 'danger',
            },
            'chat-container',
          );
        }
      }
    };

    if (conversations.length > 0) {
      await fetchConversationInfo(conversations);
      await fetchPartcipants(conversations);
    }
    setIsFetchingConversationInfo(false);
  };

  const fetchCaseConversation = async () => {
    try {
      if (selectedCaseId) {
        setMessagesLoading(true);
        const [conversations, [email]] = await Promise.all([
          caseClient.getConversations(selectedCaseId),
          caseClient.getEmails(selectedCaseId),
        ]);
        setConversation(conversations[0]);
        setOriginalCaseEmail(email);
        if (conversations.length > 0) {
          await fetchConversationInformation(conversations);
        }
        // get the conversation messages
        if (conversations.length > 0) {
          const messages = await axios.request({
            url: `https://api.sandbox.odinfusion.com/ChatModule/v1.0/gateway/conversation/${conversations[0].id}/messages`,
            method: 'get',
            headers: getHeaders(),
          });
          setMessages(messages?.data.data);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setMessagesLoading(false);
    }
  };

  const sendMessage = async (message: string) => {
    try {
      if (conversation) {
        setIsSendingMessage(true);
        await caseClient.customApi<any>({
          path: `ChatModule/v1.0/gateway/conversation/${conversation.id}/message/send`,
          method: 'post',
          body: {
            conversationSid: conversation.id,
            message: message,
            from: userReducer?.user.email,
          },
        });
        setMessage('');
        setIsSendingMessage(false);
      }
    } catch (e) {
      setIsSendingMessage(false);
      console.error(e);
    }
  };

  const handleSendThumbsUp = async () => {
    try {
      if (conversation) {
        setIsSendingMessage(true);
        await caseClient.customApi<any>({
          path: `ChatModule/v1.0/gateway/conversation/${conversation.id}/message/send`,
          method: 'post',
          body: {
            conversationSid: conversation.id,
            message: '👍',
            from: userReducer?.user.email,
            source: 'browser',
          },
        });
        setIsSendingMessage(false);
      }
    } catch (e) {
      setIsSendingMessage(false);
      console.error(e);
    }
  };

  const handleAddParticipant = async () => {
    if (conversation) {
      setIsAddParticipantLoading(true);
      const addParticipant = await caseClient.customApi<any>({
        path: `ChatModule/v1.0/gateway/conversation/${conversation.id}/participant/add`,
        method: 'post',
        body: {
          identifier: participantNameValue,
        },
      });
      if (addParticipant.participantId) {
        setOpenParticipantDrawer(false);
        AppToaster({ toasterPosition: 'bottom' }).show(
          {
            message: `Participant added successtuly with ID: ${addParticipant?.participantId}`,
            intent: 'success',
          },
          'chat-container',
        );
        await fetchConversationInformation([conversation]);
        setIsAddParticipantLoading(false);
      }
    }
  };

  const handleIncomingMessage = async (message: any) => {
    if (message.caseId === selectedCaseId) {
      setMessages((prevMessages: any) => [
        ...prevMessages,
        {
          id: message.messageId,
          properties: {
            From: message.from,
            Body: message.message,
          },
          hourSent: message.hourSent,
          dateSent: message.dateSent,
        },
      ]);
    } else {
      setNewMessageCounters((prevCounters: any) => {
        const existingCase = prevCounters.find(
          (caseCounter: any) => caseCounter.caseId === message.caseId,
        );

        if (existingCase) {
          return prevCounters.map((caseCounter: any) =>
            caseCounter.caseId === message.caseId
              ? { ...caseCounter, counter: caseCounter.counter + 1 }
              : caseCounter,
          );
        } else {
          return [...prevCounters, { caseId: message.caseId, counter: 1 }];
        }
      });
    }
  };

  const handleEndConversation = async (data: any) => {
    console.log(`Conversation ended: ${data.conversationSid}`);
  };

  return (
    <div className="my-cases-container">
      <Row style={{ paddingBottom: '0', maxHeight: '90vh' }}>
        <Col span={5} style={{ maxHeight: 'inherit' }}>
          <Card style={{ height: '100%' }}>
            <H5 style={{ margin: '10px 0' }}>My Cases</H5>
            {myCasesLoading ? (
              <Spinner />
            ) : (
              <MyCasesComponent
                myCases={myCases}
                newMessageCounters={newMessageCounters}
                selectedCaseId={selectedCaseId || ''}
                setNewMessageCounters={setNewMessageCounters}
                setSelectedCase={setSelectedCase}
              />
            )}
          </Card>
        </Col>
        <Col span={7} style={{ backgroundColor: '#fff', maxHeight: 'inherit' }}>
          <Card className="chat-card">
            {conversation ? (
              <ChatTopBar
                conversation={conversation}
                handleConfirmEndConversation={() => true}
                isEndingConversation={false}
                setShowEndConversationAlert={setShowEndConversationAlert}
                showEndConversationAlert={showEndConversationAlert}
              />
            ) : (
              <></>
            )}
            <Tabs
              selectedTabId={selectedMiddleTab}
              onChange={(tabId) => setSelectedMiddleTab(tabId as string)}
              className="chat-tabs"
            >
              <Tab
                id="info-tab"
                title="Info"
                panel={
                  <ConversationDetailsCard
                    fetchConversationInformation={fetchConversationInformation}
                    caseClient={caseClient}
                    conversation={conversation}
                    conversationInformation={conversationInfo}
                    conversationParticipants={conversationParticipants}
                    isLoading={isFetchingConversationInfo}
                    setOpenParticipantDrawer={setOpenParticipantDrawer}
                  />
                }
              />
              <Tab
                id={'chat-tab'}
                title="Chat"
                panel={
                  <ChatFeed
                    isLoading={messagesLoading}
                    conversation={conversation}
                    handleSendThumbsUp={handleSendThumbsUp}
                    isSendingMessage={isSendingMessage}
                    message={message}
                    messages={messages}
                    messagesContainerRef={messagesContainerRef}
                    sendMessage={() => sendMessage(message)}
                    setMessage={setMessage}
                    setOpenParticipantDrawer={setOpenParticipantDrawer}
                    textareaRef={textareaRef}
                    userReducer={userReducer}
                    originalCaseEmail={originalCaseEmail}
                  />
                }
              />
              {!!originalCaseEmail && (
                <Tab
                  id="email-tab"
                  title="E-mail"
                  panel={
                    <EmailFeed
                      parentEmailId={originalCaseEmail.id}
                      messagesLoading={messagesLoading}
                      userReducer={userReducer}
                    />
                  }
                />
              )}
            </Tabs>
          </Card>
        </Col>
        <Col span={12} style={{ maxHeight: 'inherit', backgroundColor: '#fff' }}>
          <Card
            style={{
              overflowY: 'auto',
              height: '100%',
            }}
          >
            <H5 style={{ margin: '10px 0px' }}>Case</H5>
            <div>
              {!caseDetail ? (
                <span>Select a case</span>
              ) : caseDetailLoading ? (
                <div className="spinner-container">
                  <Spinner />
                </div>
              ) : (
                <CaseDetailComponent caseDetail={caseDetail} userReducer={userReducer} />
              )}
            </div>
          </Card>
        </Col>
      </Row>
      <div
        style={{
          justifySelf: 'center',
        }}
      >
        <CustomDrawer
          isOpen={openParticipantDrawer}
          onClose={() => setOpenParticipantDrawer(false)}
          position="bottom"
          canOutsideClickClose={true}
          canEscapeKeyClose={true}
          title="Add a participant"
          hasDescription
          description="Fill out the details to add a participant"
          hasSaveButton
          onSaveButtonClick={handleAddParticipant}
          onCancelButtonClick={() => setOpenParticipantDrawer(false)}
          isLoading={isAddParticipantLoading}
        >
          <div style={{ marginTop: '0.5rem', padding: '1rem' }}>
            <InputGroup
              ref={participantInputRef}
              large={true}
              placeholder="Enter participant's name"
              small={false}
              type="text"
              onChange={(e) => {
                setParticipantNameValue(e.currentTarget.value);
              }}
              style={{ borderRadius: '10px', margin: '10px 0' }}
              disabled={isAddParticipantLoading}
            />
          </div>
        </CustomDrawer>
      </div>
    </div>
  );
};

const MyCasesComponent = ({
  myCases,
  newMessageCounters,
  selectedCaseId,
  setSelectedCase,
  setNewMessageCounters,
}: {
  myCases: any[];
  newMessageCounters: Array<any>;
  setNewMessageCounters: (value: any) => void;
  selectedCaseId: string;
  setSelectedCase: (caseId: string) => void;
}) => {
  const [clickedCaseIds, setClickedCaseIds] = useState([]);
  const handleCaseClick = (caseId: string) => {
    setSelectedCase(caseId);
    setClickedCaseIds((prev: any) => {
      if (prev.includes(caseId)) {
        setNewMessageCounters((prevCounters: any) => {
          return prevCounters.filter((caseCounter: any) => caseCounter.caseId !== caseId);
        });
        return prev.filter((id: string) => id !== caseId);
      }
      return [...prev, caseId];
    });
    setNewMessageCounters((prevCounters: any) => {
      return prevCounters.filter((caseCounter: any) => caseCounter.caseId !== caseId);
    });
  };
  return (
    <div style={{ height: 'calc(-70px + 90vh)', overflowY: 'auto' }}>
      {myCases.map((elem) => {
        const caseCounter: any = newMessageCounters.find(
          (caseCounter: any) => caseCounter.caseId === elem.id,
        );
        const notificationCount = caseCounter ? caseCounter.counter : 0;
        return (
          <Card
            key={elem.id}
            interactive
            onClick={() => {
              handleCaseClick(elem.id);
            }}
            className="my-cases-card-container"
            style={{ backgroundColor: selectedCaseId === elem.id ? 'white' : 'inherit' }}
          >
            <div className="my-cases-wrapper">
              <div
                className="my-cases-channel"
                style={{
                  backgroundColor: `${
                    elem?.conversationInfo?.Channel === 'SMS'
                      ? 'hsl(0deg 75.6% 50.8% / 62%)'
                      : elem?.conversationInfo?.Channel === 'CHAT'
                      ? 'hsl(173, 77%, 31%)'
                      : elem?.conversationInfo?.Channel === 'FACEBOOK_MESSENGER'
                      ? 'hsl(221, 44%, 41%)'
                      : elem?.conversationInfo?.Channel === 'WHATSAPP'
                      ? 'hsl(142, 70%, 49%)'
                      : '#3b60c4'
                  }`,
                  textAlign: 'center',
                }}
              >
                {elem?.conversationInfo?.Channel === 'CHAT' ? (
                  <Chat size={20} color="white" />
                ) : elem?.conversationInfo?.Channel === 'FACEBOOK_MESSENGER' ? (
                  <FacebookIcon />
                ) : elem?.conversationInfo?.Channel === 'SMS' ? (
                  <MobilePhone size={30} color="white" />
                ) : elem?.conversationInfo?.Channel === 'WHATSAPP' ? (
                  <WhatsappIcon />
                ) : (
                  <Inbox size={30} color="white" />
                )}
              </div>
              <div className="my-cases-card-top-bar">
                <div className="my-cases-card-title-container">
                  <H6 className="my-cases-card-title">{elem?.recordNumber}</H6>
                  {notificationCount > 0 && (
                    <div
                      style={{
                        backgroundColor: 'red',
                        borderRadius: '50%',
                        width: 16,
                        height: 16,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        fontSize: 8,
                        margin: '0 0 10px',
                      }}
                    >
                      {notificationCount}
                    </div>
                  )}
                </div>
                <p className="my-cases-card-description">
                  {elem.title.length > 20 ? `${elem.title.substring(0, 17)}...` : elem.title}
                </p>
              </div>
              <div>
                {elem?.stage?.name === 'Open' ? (
                  <div className="my-cases-status-chip" style={{ padding: 8 }}>
                    <span className="mr-7">{elem?.stage?.name}</span>
                    <div className="green-light" />
                  </div>
                ) : (
                  <div className="my-cases-status-chip" style={{ padding: 8 }}>
                    <span className="mr-7">{elem?.stage?.name}</span>
                    <div className="red-light" />
                  </div>
                )}
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

const CaseDetailComponent = ({
  caseDetail,
  userReducer,
}: {
  caseDetail: any;
  userReducer: any;
}) => {
  const { schema } = useContext(DetailViewContext);
  const [selectedTab, setSelectedTab] = useState<string | undefined>(undefined);

  return (
    <>
      <HeaderDetailView
        record={caseDetail}
        extra={[
          caseDetail && schema && (
            <SchemaActionPageHeader
              moduleName={schema.moduleName}
              entityName={schema.entityName}
              recordId={caseDetail.id}
              launcherType="INLINE"
            />
          ),
        ]}
      />
      <RecordStageSequentialPipeline className="record-pipeline" record={caseDetail} styles={{}} />
      <div>
        <Callout icon={null} style={{ marginBottom: '10px' }}>
          <Descriptions column={1}>
            <Descriptions.Item label={'Impact'}>
              <Tag minimal>{getProperty(caseDetail, 'Impact')}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Queue">
              {getProperty(caseDetail, 'R_QueueTitle')}
            </Descriptions.Item>
            <Descriptions.Item label="Subject">{caseDetail.title}</Descriptions.Item>
            <Descriptions.Item label="Description">
              {getProperty(caseDetail, 'Description')}
            </Descriptions.Item>
            <Descriptions.Item label="Assigned To">
              {getProperty(caseDetail, 'OwnerId') === userReducer.user.id
                ? `${userReducer.user.firstname} ${userReducer.user.lastname || ''}`
                : caseDetail.caseUser[0].name}
            </Descriptions.Item>
          </Descriptions>
        </Callout>
        <div style={{ marginBottom: '10px' }}>
          <Tabs selectedTabId={selectedTab} onChange={(tabId) => setSelectedTab(tabId as string)}>
            <Tab
              id="info"
              title="Info"
              panel={
                <RecordCard
                  showActionMenu
                  borderless
                  showItemActionMenu
                  shouldPollData={true}
                  showWithoutPagination={true}
                  pollingIntervalSeconds={5}
                  showRecordStage={true}
                  record={caseDetail!}
                  displayQuickView
                  associatedRecordModuleName={SchemaModuleTypeEnums.CRM_MODULE}
                  associatedRecordEntityName={SchemaModuleEntityTypeEnums.CONTACT}
                  visibleProperties={['Phone', 'EmailAddress']}
                />
              }
            />
            <Tab id="notes" title="Notes" panel={<NoteFeed record={caseDetail} />} />
          </Tabs>
        </div>
      </div>
    </>
  );
};

const getQueryParams = (userReducer: any) => ({
  query: {
    entity: 'infer',
    type: 'and',
    value: [
      {
        columnName: 'OwnerId',
        operator: 'eq',
        value: userReducer.user.id,
      },
    ],
    returnProperties: ['*'],
    sort: {
      createdAt: {
        order: 'asc',
      },
    },
    pageSize: 1000,
    pageNumber: 0,
  },
});

const getHeaders = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
});

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCasesView);
