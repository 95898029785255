import { Tag } from '@blueprintjs/core';
import React from 'react';
import './styles.scss';

interface Props {
  status: 'NEW' | 'OPEN' | 'ASSIGNED' | 'BLOCKED' | 'CLOSED' | 'PENDING' | 'PENDING_AGENT' | 'PENDING_CUSTOMER' | 'ON_HOLD' | 'SOLVED';
}

const CommunicationsStatusTag: React.FC<Props> = (props: Props) => {
  const { status } = props;
  const sanitizeTagName = () => {
    switch (status) {
      case 'OPEN':
        return 'Open';
      case 'ASSIGNED':
        return 'Assigned';
      case 'BLOCKED':
        return 'Blocked';
      case 'CLOSED':
        return 'Closed';
      case 'PENDING':
        return 'Pending';
      case 'PENDING_AGENT':
        return 'Pending';
      case 'PENDING_CUSTOMER':
        return 'Pending';
      case 'ON_HOLD':
        return 'On Hold';
      case 'SOLVED':
        return 'Solved';
      default:
        return 'Unknown';
    }
  };

  return <Tag className={`communicationsStatusTag ${status}`}>{sanitizeTagName()}</Tag>;
};
export default CommunicationsStatusTag;
